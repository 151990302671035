import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, PreviewBody } from 'components';
import FourOhFourImage from 'images/404.svg';
import './404.scss';

const FourOhFour = ({ location }) => {
  const [isPreviewEnv, setIsPreviewEnv] = useState(false);

  useEffect(() => {
    const { PrismicToolbar } = window;

    setIsPreviewEnv(!!PrismicToolbar);
  }, []);

  if (isPreviewEnv) {
    return (
      <Layout location={location}>
        <PreviewBody />
      </Layout>
    );
  }

  return (
    <Layout location={location}>
      <div className="wrapper not-found-wrap">
        <img className="not-found-image" src={FourOhFourImage} alt="Opps this page has gone missing" />
        <div className="not-found-text">
          <h2>404 Not found</h2>
          <Link className="button" to="/">
            Go back home
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicUnpublishedPreview(FourOhFour);
